<template>
  <div
    class="px-2 sm:px-4 lg:px-6 py-2 flex bg-gray-50 text-gray-600 justify-between lg:justify-center items-center text-center shadow"
  >
    <span
      v-if="Array.isArray(pagenation)"
      class="lg:absolute capitalize lg:left-4"
      >{{ t('rows') }}
      <select
        class="outline-none hover:text-blue-400 ml-1 focus:ring-2 rounded py-1 shadow hover:shadow-md px-2 cursor-pointer transition duration-200"
        :value="rows"
        :title="t('lines')"
        @input="onInputRow"
      >
        <option v-for="row in pagenation" :key="row" :value="row">
          {{ row === 'ALL' ? t('all') : row }}
        </option>
      </select>
    </span>
    <span class="space-x-3">
      <button
        class="shadow hover:shadow-md hover:text-blue-400 p-1 focus:ring-2 transition outline-none duration-200 rounded disabled:text-gray-300"
        :disabled="disabledPrev"
        :title="t('prev')"
        @click="$emit('prev')"
      >
        <mdi-chevron-left />
      </button>
      <select
        v-if="pages > 1"
        :value="modelValue"
        :title="pageNumber"
        class="appearance-none transition bg-gray-50 duration-200 hover:text-blue-400 outline-none cursor-pointer focus:ring-2 py-2 px-3 rounded shadow hover:shadow-md"
        @input="onInput"
      >
        <option v-for="p in pages" :key="p" :value="p">
          {{ t('per', [p, pages]) }}
        </option>
      </select>

      <span
        v-else
        class="py-2 px-4 rounded shadow"
        :title="modelValue.toString()"
        >{{ modelValue }}</span
      >
      <button
        class="shadow hover:shadow-md hover:text-blue-400 focus:ring-2 p-1 transition outline-none duration-200 rounded disabled:text-gray-300"
        :disabled="disabledNext"
        :title="t('next')"
        @click="$emit('next')"
      >
        <mdi-chevron-right />
      </button>
    </span>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import MdiChevronLeft from '@/components/base/icons/mdi/MdiChevronLeft.vue'
import MdiChevronRight from '@/components/base/icons/mdi/MdiChevronRight.vue'
import { Item } from '@/components/base/BaseTable/logic'
import { DEFAULT_ROWS } from '@/components/base/BaseTable/logic'
import { toUpper } from 'rambda'
export default defineComponent({
  components: {
    MdiChevronLeft,
    MdiChevronRight,
  },

  props: {
    pagenation: {
      type: [Number, Boolean, Array as () => number | 'ALL'[]],
      default: false,
    },

    rows: {
      type: Number,
      default: DEFAULT_ROWS,
    },

    items: {
      type: Array as () => Item[],
      default: () => [],
    },

    modelValue: {
      type: Number,
      default: 1,
    },

    pages: {
      type: Number,
      default: 1,
    },

    disabledPrev: {
      type: Boolean,
      default: false,
    },

    disabledNext: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['prev', 'next', 'update:modelValue', 'update:rows'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const pageNumber = computed<string>(
      () => `${props.modelValue}/${props.pages}`
    )

    const onInput = ({ target }: Event): void => {
      if (target instanceof HTMLSelectElement) {
        emit('update:modelValue', Number(target?.value))
      }
    }

    const onInputRow = ({ target }: Event): void => {
      if (target instanceof HTMLSelectElement) {
        const _value = target.value
        const value = toUpper(_value) === 'ALL' ? 'ALL' : Number(_value)
        emit('update:rows', value)
      }
    }

    return {
      t,
      onInput,
      onInputRow,
      pageNumber,
    }
  },
})
</script>

<i18n lang="yml">
en:
  prev: Prev
  next: Next
  per: '{0}/{1}'
  rows: rows
  lines: Number of lines
  all: ALL

ja:
  prev: 前のページ
  next: 次のページ
  per: '{0}/{1}'
  rows: 行
  lines: 行数
  all: すべて
</i18n>
