
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import MdiChevronLeft from '@/components/base/icons/mdi/MdiChevronLeft.vue'
import MdiChevronRight from '@/components/base/icons/mdi/MdiChevronRight.vue'
import { Item } from '@/components/base/BaseTable/logic'
import { DEFAULT_ROWS } from '@/components/base/BaseTable/logic'
import { toUpper } from 'rambda'
export default defineComponent({
  components: {
    MdiChevronLeft,
    MdiChevronRight,
  },

  props: {
    pagenation: {
      type: [Number, Boolean, Array as () => number | 'ALL'[]],
      default: false,
    },

    rows: {
      type: Number,
      default: DEFAULT_ROWS,
    },

    items: {
      type: Array as () => Item[],
      default: () => [],
    },

    modelValue: {
      type: Number,
      default: 1,
    },

    pages: {
      type: Number,
      default: 1,
    },

    disabledPrev: {
      type: Boolean,
      default: false,
    },

    disabledNext: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['prev', 'next', 'update:modelValue', 'update:rows'],
  setup(props, { emit }) {
    const { t } = useI18n()
    const pageNumber = computed<string>(
      () => `${props.modelValue}/${props.pages}`
    )

    const onInput = ({ target }: Event): void => {
      if (target instanceof HTMLSelectElement) {
        emit('update:modelValue', Number(target?.value))
      }
    }

    const onInputRow = ({ target }: Event): void => {
      if (target instanceof HTMLSelectElement) {
        const _value = target.value
        const value = toUpper(_value) === 'ALL' ? 'ALL' : Number(_value)
        emit('update:rows', value)
      }
    }

    return {
      t,
      onInput,
      onInputRow,
      pageNumber,
    }
  },
})
